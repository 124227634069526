<template>
    <div class="dashboard bookings locations costSaleServices">
        <div class="head">
            <div class="title">
                <span> Service Types </span>
            </div>
        </div>
        
        <div class="search-booking">
            <div class="item">
                <label for="">Fechas</label>
                <input-date-picker v-model="date" :currentDate="date"></input-date-picker>    
            </div>
            <div class="item">
                <vs-button color="primary"  @click="search()" type="filled" icon="search" :disabled="!this.date != ''"></vs-button>
                <vs-button color="warning"  @click="reset()" type="filled" icon="backspace" v-if="date != ''"></vs-button>
            </div>
        </div>

        <div class="title book-title">Line Graph</div>

        <div class="box-white">
            <line-chart :key="key" :labels="stats.labels" :options="stats.value" title="Service Types" v-if="loaded"></line-chart>
        </div>
        
        <div class="title book-title">Summary</div>

        <table-service-types Types :items="stats.items"></table-service-types>

        <modal :show="modalShow" :dark="true" title="Código Acceso">
            <div slot="modal-body">
                <div class="modal-content" style="padding: 35px 35px 15px">
                    <p>Por favor ingresar el código de acceso</p>
                    <br>
                    <vs-input v-model="codeAccess"  :danger="errorCode" danger-text="Código incorrecto" v-on:keyup.enter="validAccess()"/>
                </div>
            </div>
            <div slot="modal-footer">
                <div class="modal-footer">
                    <vs-button color="primary" type="border" @click="validAccess()" :disabled="disabled">Ingresar</vs-button>
                </div>
            </div>
        </modal>

    </div>
</template>
<script>
import { createNamespacedHelpers } from 'vuex'
import tableServiceTypes from '@/components/Tables/Stats/ServiceTypes.vue'
import BookingService from '@/services/BookingService'
import modal from "@/components/Modals/Basic.vue";
import inputDatePicker from '@/components/Inputs/input-date-range.vue'
const  { mapActions: mapAppSettingActions } = createNamespacedHelpers('appSetting')

import lineChart  from '@/components/Charts/Line'

export default {
    name: "StatsServTypeView",
    components: {
        modal,
        lineChart,
        tableServiceTypes,
        inputDatePicker,
    },
    data () {
        return {
            key: 1,
            totalPost: 26,
            date: "",
            value: "All",
            modalShow: true,
            codeAccess: "",
            errorCode: false,
            status: "",
            disabled: false,
            stats: [],
            loaded:false,
            
        }
    },
    async created () {
        let code =  JSON.parse(localStorage.getItem('CDG'));
        this.codeAccess = code
        if(this.codeAccess != "" || this.disabled != undefined){
            this.validAccess();
        }
        await this.getStatsServTypes(); 
    },
    methods: {
        ...mapAppSettingActions(["LOADING_STATUS"]),
        async getStatsServTypes(){            
            this.LOADING_STATUS(true);
            let response = await BookingService.getStatsServTypes({
                "begDate" : this.date != '' ? this.date.start : "", //opcional
                "endDate" : this.date != '' ? this.date.end : "",//opcional
            });            
            if((response.status/100) == 2){
                this.stats  = response.data
                this.loaded = true
                this.key++
            }            
            this.LOADING_STATUS(false);
        },
        search() {
            this.getStatsServTypes()
        },
        reset() {
            this.date = "";
            this.getStatsServTypes()
        },
        validAccess() {
            if(this.codeAccess == "asuaire2022") {
                this.modalShow = false
                this.status = "done"
                localStorage.setItem('CDG', JSON.stringify(this.codeAccess))
            } else {
                this.errorCode = true
            }
        },
    }
}
</script>